import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: 'ru',
    page: 'im',
  },
  getters: {
    CURENT_LANG: (state) => {    
      if (state.lang == 'ru') {
        return 'en'
      } else {
        return 'ru'
      }
    }, 
    TITELS : (state) => {
      if (state.page != 'start') {
        return [
          'nitros',
          'About',
          // 'Documentation',
          'Download',
          'Contacts',
          'Русский',
        ]
      } else {
        return [
            'nitros',
            'About',
            'Contacts',
            'Русский',
          ]
      }
    },
    GET_PAGE : (state) => {
      return state.page
    },
    GET_LANG : (state) => {
      return state.lang
    }
  },
  mutations: {
    SET_LANG: (state) => {
      // console.log(value);
      if (state.lang == 'ru') {
        state.lang = 'en'        
      } else {
        state.lang = 'ru'  
      }
    },
    SET_PAGE: (state, page) => {
      state.page = page
    }
  },
  actions: {
    SET_LANG: (contex) => {
      contex.commit('SET_LANG')
    },
    setPage: (contex,page) => {
      console.log('setPage: ',page);
      contex.commit('SET_PAGE',page)
    }
  },
  modules: {
  }
})
/*

*/