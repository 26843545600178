import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from "../views/layout.vue";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy.vue";
import Sql from "../views/Sql/sql.vue";
import Mm from "../views/Mm/mm.vue";
import Rdf from "../views/Rdf/Rdf.vue";
import Im from "../views/Im/im.vue";

const routes = [
  {
    path: '/',
    name: 'app',
    component: Layout,
    children: [      
      {
        name: 'Im',
        path: '',
        component: Im
      },
    ]
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

export default router
